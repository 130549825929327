<template lang="pug">
	v-app

		dashboard-core-navigation-drawer( :switch-drawer="switchDrawer" )

		dashboard-core-app-bar( @onSwitchDrawerEvent="switchDrawerEvent" )

		dashboard-core-view

</template>
<script>
import SessionService from '../../services/session.service'
const userService = new SessionService();
export default {
    name: 'DashboardIndex',
    data() {
		return {
			switchDrawer: false,
		}
    },
    methods: {
		//clic abrir abrir navitation drawer
		switchDrawerEvent() {
			this.switchDrawer = !this.switchDrawer;
		}
    },
    components: {
		DashboardCoreAppBar: () => import('./components/core/AppBar'),
		DashboardCoreView: () => import('./components/core/View'),
		DashboardCoreNavigationDrawer: () => import('./components/core/NavigationDrawer'),
    },
	created() {
		userService.me()
		.then(response => {
			this.$store.dispatch('session/me', response.data )
		})
		.catch(error => {
			console.log(error)
		})
	}
}
</script>