import BackendAuthService from "./BackendAuthService";

export default class SessionService extends BackendAuthService {

	constructor() {
		super();
	}

    // permissions() {
	// 	return this.httpCall('api/permissions/', {
	// 		method: 'GET'
	// 	});
	// }

	me() {
		return this.httpCall('api/v1/me', {
			method: 'GET'
		});
	}

}
